import styled from 'styled-components';

const FlexEndRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  justify-content: end;
`;

export default FlexEndRow;
