import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WorkerProvider } from './contexts/WorkerContext';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import Loading from './components/atoms/Loading';
import ProtectedRoute from './components/templates/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';

import Home from './pages/Home';
import Login from './pages/Login';

const Project = lazy(() => import('./pages/Project'));
const DeviceDetails = lazy(() => import('./pages/DeviceDetails'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <WorkerProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={(
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              )} />
              <Route path="/project/:projectId" element={(
                <ProtectedRoute>
                  <Project />
                </ProtectedRoute>
              )} />
              <Route path="/devices/:devEui" element={(
                <ProtectedRoute>
                  <DeviceDetails />
                </ProtectedRoute>
              )} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </WorkerProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Suspense>
  </React.StrictMode>
);
