import styled from 'styled-components';

const TextInput = styled.input`
  padding: 10px 8px;
  width: 300px;

  border-radius: 6px;
  border: 1px solid #777777;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px #777777;
  }
`;

export default TextInput;
