import { FC, PropsWithChildren, createContext, startTransition, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { randomUUID } from '../utils/random-uuid';
import { Id as ToastId, ToastOptions, UpdateOptions, toast } from 'react-toastify';
import { ProgressUpdateEvent, ToastNotificationEvent } from '../worker-actions/messaging';
import ProgressUpdate from '../components/molecules/ProgressUpdate';
import CustomToast from '../components/molecules/CustomToast';
import { EventType } from '../worker';

type WorkerContextType = {
  subscribe(callback: (message: MessageEvent) => void): string;
  unsubscribe(id: string): void;
  publish(type: EventType, data?: any): void;
}

const WorkerContext = createContext<WorkerContextType>({
  subscribe: (callback: (message: MessageEvent) => void): string => { throw new Error('WorkerContext not initialized') },
  unsubscribe: (id: string): void => { throw new Error('WorkerContext not initialized') },
  publish: (type: EventType, data?: any): void => { throw new Error('WorkerContext not initialized') }
});

export const WorkerProvider: FC<PropsWithChildren> = ({ children }) => {
  const worker = useMemo(() => new Worker(new URL('../worker.ts', import.meta.url)), []);
  const subscribers = useMemo(() => new Map<string, (message: MessageEvent) => void>(), []);
  const toastProgress = useRef<ToastId>();

  const createToastOptions = (progressUpdate: ProgressUpdateEvent, withRender: boolean): ToastOptions | UpdateOptions => {
    const baseConfig: ToastOptions = {
      progress: progressUpdate.progress,
      type: progressUpdate.status,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      draggable: false,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      hideProgressBar: false,
      isLoading: !progressUpdate.autoClose
    };

    if (withRender) {
      const updateOptions: UpdateOptions = {
        ...baseConfig, 
        render: <ProgressUpdate progressUpdate={progressUpdate} />
      };
    
      return updateOptions;
    }

    return baseConfig;
  }

  const onUpdateProgress = useCallback((event: MessageEvent) => {
    const progressUpdate: ProgressUpdateEvent = event.data.result;
    console.log(`[${(progressUpdate.progress * 100).toFixed(2)}][${progressUpdate.title}] ${progressUpdate.message}`);

    if (!toastProgress.current) {
      toastProgress.current = toast(<ProgressUpdate progressUpdate={progressUpdate} />, createToastOptions(progressUpdate, false) as ToastOptions);
      return;
    }

    if (!progressUpdate.autoClose) {
      toast.update(toastProgress.current, createToastOptions(progressUpdate, true));
      return;
    }

    toast.done(toastProgress.current);
    toastProgress.current = undefined;
  }, [])

  const onToast = (event: MessageEvent) => {
    const notification: ToastNotificationEvent = event.data.result;
    toast(<CustomToast notification={notification} />, {
      type: notification.status
    });
  }

  const notifySubscribers = useCallback((event: MessageEvent) => {
    subscribers.forEach((callback) => {
      startTransition(() => {
        callback(event)
      });
    });
  }, [subscribers]);

  useEffect(() => {
    if (window.Worker) {
      worker.onmessage = (event: MessageEvent) => {
        switch (event.data.type) {
          case 'PROGRESS':
            startTransition(() => {
              onUpdateProgress(event);
            });
            break;
          case 'TOAST':
            onToast(event);
            break;
          default:
            notifySubscribers(event);
            break;
        }
      };
    }
  }, [worker, notifySubscribers, onUpdateProgress]);

  const subscribe = (callback: (message: MessageEvent) => void): string => {
    const id = randomUUID();
    subscribers.set(id, callback);

    return id;
  };

  const unsubscribe = (id: string) => {
    subscribers.delete(id);
  };

  const publish = (type: EventType, data?: any) => {
    worker.postMessage({
      type,
      ...data
    });
  };

  return (
    <WorkerContext.Provider
      value={{
        subscribe,
        unsubscribe,
        publish
      }}>
      {children}
    </WorkerContext.Provider>
  )
};

export const useWorkerContext = () => useContext(WorkerContext);
