
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, "0")
}

export const randomUUID = (len?: number) => {
  const arr = new Uint8Array((len || 20) / 2);
  crypto.getRandomValues(arr);

  return Array.from(arr, dec2hex).join('');
}
