import { styled } from 'styled-components'
import Sidebar from '../organisms/Sidebar';
import { PropsWithChildren } from 'react';

const PageLayout = ({ children }: PropsWithChildren) => {

  return (
    <Container>
      <Sidebar />
      <Content>
        {children}
      </Content>
    </Container>
  )
}

export default PageLayout;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  height: 100vh;
  overflow: auto;
`;
