import { styled } from 'styled-components';

const Modal = styled.div<{ open: boolean }>`
  display: ${({ open }) => open ? 'flex' : 'none'};

  position: fixed;
  z-index: 2;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);

  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: block;

  padding: 60px 100px;
  border-radius: 8px;

  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export { Modal, ModalContainer };
