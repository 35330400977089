import { FC, PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../atoms/Loading';

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { isLoading, isLoggedIn } = useAuthContext();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      navigate('/login');
    }
  }, [isLoading, isLoggedIn, navigate])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {children}
    </>
  )
}

export default ProtectedRoute;
