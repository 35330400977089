import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <SidebarContainer>
      <SidebarHeader onClick={() => navigate('/')}>
        <h2>Wanesy Utilities</h2>
      </SidebarHeader>
      <SidebarBody>
        <SiderbarLinks>
          <li onClick={() => navigate('/')}>Projects</li>
        </SiderbarLinks>
      </SidebarBody>
    </SidebarContainer>
  );
}

export default Sidebar;

const SidebarContainer = styled.div`
  background-color: #fff;

  border-right: 1px solid #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  height: 100vh;
  width: 250px;
  overflow: auto;
`;

const SidebarHeader = styled.div`
  margin: 20px;

  cursor: pointer;
`;

const SidebarBody = styled.div`
  margin: 0 20px;
`;

const SiderbarLinks = styled.ul`

  li {
    font-size: 16px;
    cursor: pointer;
  }
`;
