import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { useWorkerContext } from "./WorkerContext";

type AuthContextType = {
  isLoading: boolean;
  isLoggedIn: boolean;
  login: (username: string, password: string) => void;
}

const AuthContext = createContext<AuthContextType>({
  isLoading: true,
  isLoggedIn: false,
  login: (username: string, password: string): void => { throw new Error('Not implemented') }
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { subscribe, unsubscribe, publish } = useWorkerContext();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onMessageHandler = (event: MessageEvent) => {
    switch (event.data.type) {
      case 'RESTORE':
        setIsLoading(false);
        setIsLoggedIn(event.data.result);
        break;
      case 'LOGIN':
        setIsLoading(false);
        setIsLoggedIn(event.data.result);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    const id = subscribe(onMessageHandler);

    setIsLoading(true);
    publish('RESTORE');

    return () => unsubscribe(id);
  }, [subscribe, unsubscribe, publish])

  const login = (username: string, password: string): void => {
    setIsLoading(true);
    publish('LOGIN', { username, password });
  }

  return (
    <AuthContext.Provider value={{
      isLoading,
      isLoggedIn,
      login
    }}>
      { children }
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext);
