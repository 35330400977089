import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { toast } from 'react-toastify';
import Project from '../models/Project';
import Button from '../components/atoms/Button';
import ToastSuccessOptions from '../components/templates/ToastSuccessOptions';
import PageLayout from '../components/templates/PageLayout';
import { useWorkerContext } from '../contexts/WorkerContext';
import ProjectCreateModal from './modals/ProjectCreateModal';

const Home = () => {
  const navigate = useNavigate();
  const { subscribe, unsubscribe, publish } = useWorkerContext();
  const [projects, setProjects] = useState<Project[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const id = subscribe(onMessageHandler);

    publish('LIST_PROJECTS');

    return () => unsubscribe(id);
  }, [subscribe, unsubscribe, publish]);

  const onMessageHandler = (event: MessageEvent) => {
    switch (event.data.type) {
      case 'LIST_PROJECTS':
        setProjects(event.data.result);
        break;
      case 'CREATE_PROJECT':
        setProjects(event.data.result.projects);

        toast.success(`Project '${event.data.result.createdProject.name}' created`, ToastSuccessOptions);

        setModalOpen(false);
        break;
      default:
        break;
    }
  }

  const onCreateProject = async (project: Project) => {
    publish('CREATE_PROJECT', { project });
  }

  return (
    <PageLayout>
      <Container>
        <h2>Projects</h2>
        
        <Button label="Open create new project dialog" onClick={() => setModalOpen(true)}>New</Button>

        <ProjectsContainer>
          {projects.map((project) => {
            return (
              <ProjectBox key={project.id} onClick={() => navigate(`/project/${project.id}`)}>
                <ProjectName>{project.name}</ProjectName>
                <ProjectSite>Site: {project.site}</ProjectSite>
                <ProjectTenant>Tenant: {project.tenant}</ProjectTenant>
              </ProjectBox>
            )
          })}
        </ProjectsContainer>
      </Container>

      <ProjectCreateModal
        modalOpen={modalOpen} 
        onModalClose={() => setModalOpen(false)}
        onCreateProject={onCreateProject} />
    </PageLayout>
  );
}

export default Home;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;

  align-items: center;
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProjectBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  width: 400px;
  padding: 10px 20px;
  
  border-radius: 6px;
  border: 1px solid #777777;

  cursor: pointer;
`;

const ProjectName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ProjectSite = styled.div`
  font-size: 14px;
`;

const ProjectTenant = styled.div`
  font-size: 14px;
`;
