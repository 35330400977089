import { useForm } from 'react-hook-form';
import Button from '../../components/atoms/Button';
import Row from '../../components/templates/Column';
import TextInput from '../../components/atoms/TextInput';
import NeutralButton from '../../components/atoms/NeutralButton';
import FlexEndRow from '../../components/molecules/FlexEndRow';
import { Modal, ModalContainer } from '../../components/molecules/Modal';
import Container from '../../components/templates/Container';
import Project from '../../models/Project';

type ProjectCreateModalProps = {
  modalOpen: boolean;
  onModalClose: () => void;
  onCreateProject: (project: Project) => void;
}

const ProjectCreateModal = ({ modalOpen, onModalClose, onCreateProject }: ProjectCreateModalProps) => {
  const { register, getValues, reset } = useForm<Project>();

  const onModalClosing = () => {
    reset({ name: '', site: '', tenant: '' });
    onModalClose();
  }
  
  return (
    <Modal open={modalOpen}>
      <ModalContainer>
        <h2>Create new project</h2>
        <Container>
          <Row>
            <label htmlFor="name">Project Name</label>
            <TextInput type="text" id="name" {...register('name')} />
          </Row>

          <Row>
            <label htmlFor="site">Site</label>
            <TextInput type="text" id="site" {...register('site')} />
          </Row>

          <Row>
            <label htmlFor="tenant">Tenant</label>
            <TextInput type="text" id="tenant" {...register('tenant')} />
          </Row>

          <FlexEndRow>
            <NeutralButton label="Cancel" onClick={onModalClosing}>Cancel</NeutralButton>
            <Button label="Create a new project" onClick={() => onCreateProject(getValues())}>Create</Button>
          </FlexEndRow>
        </Container>
      </ModalContainer>
    </Modal>
  )
}

export default ProjectCreateModal;
