import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Column from '../components/templates/Column';
import TextInput from '../components/atoms/TextInput';
import { useAuthContext } from '../contexts/AuthContext';
import SubmitButton from '../components/atoms/SubmitButton';
import Form from '../components/molecules/Form';

const Login = () => {
  const navigate = useNavigate();
  const { isLoggedIn, login } = useAuthContext();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const onLoginClick = (e: any) => {
    e.preventDefault();
    login(username, password);
  }

  return (
    <Container>
      <h2>Wanesy Utilities - Login</h2>
      
      <Form target="#" method="post" onSubmit={onLoginClick}>
        <Column>
          <label htmlFor="username">Username</label>
          <TextInput type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />      
        </Column>
        
        <Column>
          <label htmlFor="password">Password</label>
          <TextInput type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />      
        </Column>

        <SubmitButton label="Log in">Log in</SubmitButton>
      </Form>

    </Container>
  );
}

export default Login;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;

  align-items: center;
`;
