import { styled } from "styled-components";
import { ToastNotificationEvent } from "../../worker-actions/messaging";

type CustomToastProps = {
  notification: ToastNotificationEvent;
}

const CustomToast = ({ notification }: CustomToastProps) => {

  return (
    <Container>
      <Title>{notification.title}</Title>
      <Message>{notification.message}</Message>
    </Container>
  )
}

export default CustomToast;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: 'Roboto Mono', monospace;

  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
`;

const Message = styled.div`
  font-size: 12px;
`;
