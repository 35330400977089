import { styled } from "styled-components";
import { ProgressUpdateEvent } from "../../worker-actions/messaging";
import Column from "../templates/Column";

type ProgressUpdateProps = {
  progressUpdate: ProgressUpdateEvent;
}

const ProgressUpdate = ({ progressUpdate }: ProgressUpdateProps) => {

  return (
    <Container>
      <Percentage>{(progressUpdate.progress * 100).toFixed(0)}%</Percentage>
      <Column>
        <Title>{progressUpdate.title}</Title>
        <Message>{progressUpdate.message}</Message>
        <Important>
          <div>DO NOT REFRESH THE BROWSER</div>
        </Important>
      </Column>
    </Container>
  )
}

export default ProgressUpdate;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;

  font-family: 'Roboto Mono', monospace;

  align-items: center;
  justify-content: flex-start;
`;

const Percentage = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
`;

const Message = styled.div`
  font-size: 12px;
`;

const Important = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  font-size: 12px;
  font-weight: bold;

  margin-top: 10px;
`;
