import { PropsWithChildren } from 'react';
import { styled } from 'styled-components';

type ButtonProps = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  styles?: React.CSSProperties;
  redButton?: boolean;
}

const Button = ({ children, onClick, label, disabled, styles, redButton }: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton onClick={onClick} aria-label={label} disabled={disabled} style={styles} $isred={redButton}>
      {children}
    </StyledButton>
  )
}

export default Button;

const StyledButton = styled.button<{ $isred?: boolean }>`
  appearance: none;
  background-color: ${p => p.$isred ? '#c83a3a' : '#2ea44f'};
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  &:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  &:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
  }

  &:hover {
    background-color: ${p => p.$isred ? '#c11010' : '#2c974b'};
  }

  &:disabled {
    background-color: ${p => p.$isred ? '#d39494' : '#94d3a2'};
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
  }

  &:active {
    background-color: ${p => p.$isred ? '#8e2929' : '#298e46'};
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
  }
`;
